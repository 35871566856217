import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Badge } from 'react-bootstrap';

import backArrow from '../assets/back-arrow.png';
import info from '../assets/information.png';

const Apply = (props) => {

    // const [isEligibleForNyanV1toV2Swap, setIsEligibleForV1ToV2Swap] = useState(false);
    // const [isLGEOver, setIsLGEOver] = useState(false);
    // const [nyanBalance, setNyanBalance]  = useState(0);

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {
            // setNyanBalance(await Balances.getMyNyanBalance());  
            // setIsEligibleForV1ToV2Swap(true);
            // setIsLGEOver(Swap.getLGEBool());
            // console.log(isEligibleForNyanV1toV2Swap)
        }
        init()
    }, []);

    return (
        <Container fluid>
           <Link to="/" className="router-link">
                <div className="back-button">
                    <img className="b-arrow-img" alt="back arrow" src={backArrow}/>
                </div>
           </Link>
           <div className="application-container">
               <p className="application-p">What's the address of the proposed token?</p>
               <div className="input-container">
                   <input placeholder="Token Address" className="input-i"></input>
               </div>
               <div className="approve-button">APPROVE</div>

               <p className="application-p">Provide a link to a completed proposal template.</p>
               <div className="input-container">
                   <input placeholder="Overview Link" className="input-i"></input>
               </div>
               <div className="overview-template">
                   <div className="info-icon">
                        <img className="b-arrow-img" alt="information image" src={info}/>
                   </div>
                   <div className="overview-text">View Overview template</div>
               </div>

               <p className="application-p">How many tokens are going to the developers?</p>
               <div className="input-container">
                   <input placeholder="DEV Supply" className="input-i"></input>
               </div>

               <p className="application-p">Address of who referred you (optional)</p>
               <div className="input-container">
                   <input placeholder="Referrer address" className="input-i"></input>
               </div>


               <div className="apply-button">APPLY</div>
           </div>
            
        </Container>
    )
}

export default Apply;