import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Badge } from 'react-bootstrap';
import { Web3Util, Accounts, Shiller } from '../utils/index';
import ShillerAppModal from './modals/shiller-app';

import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

// import { Swap, Balances } from '../utils';

const Home = (props) => {

    // const [isEligibleForNyanV1toV2Swap, setIsEligibleForV1ToV2Swap] = useState(false);
    // const [isLGEOver, setIsLGEOver] = useState(false);
    // const [nyanBalance, setNyanBalance]  = useState(0);

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {
            // setNyanBalance(await Balances.getMyNyanBalance());  
            // setIsEligibleForV1ToV2Swap(true);
            // setIsLGEOver(Swap.getLGEBool());
            // console.log(isEligibleForNyanV1toV2Swap)
            
            let instance = await Web3Util.createInstance();
            console.log(instance);
            if(instance){
                console.log(instance);
                // history.replace('/app');
                return false;
            } else {
                console.log('Cannot open app');
            }

            
        }

        init()
    }, []);

    

    return (
        <Container fluid>
            {/* <h4 className="site-title">A Nyan.finance lending product</h4> */}
            
            <div className="eth-locked">
                <div className="e-locked-text">Total ETH Locked: 0</div>
                <div className="e-locked-text">My ETH Locked: 0</div>
                <div className="eth-add">Add ETH</div>
            </div>

            <br/><br/><br/>
            
            <div className="home-header-button">
                <Link to="/apply" className="router-link">
                    <div className="link-text">
                        APPLY
                    </div>
                </Link> 
                   
            </div>
            
            <div className="home-header-button"> 
                <Link to="/vote" className="router-link">
                    <div className="link-text">
                        VOTE
                    </div>
                </Link>
            </div>
            
            <div className="home-header-button">
                <Link to="/profit" className="router-link">
                    <div className="link-text">
                        PROFIT
                    </div>
                </Link>
            </div>
            
            
            <div className="shiller-info">
                Are you a shiller with followers? Sign up for ETH airdrops!
            </div>
            {Shiller.returnShillApply() ? 
                <div className="shiller-apply-container">
                    <div className="shiller-apply">

                    </div>
                </div>
                :
                null
            }
            
        </Container>
    )
}

export default Home;