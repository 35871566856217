let isShillApply = false;
let toggleShillApply = () => {
    isShillApply = !isShillApply;
    return isShillApply;
}

let returnShillApply = () => {
    return isShillApply;
}

export {
    returnShillApply,
    toggleShillApply
 };