import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Badge } from 'react-bootstrap';

import backArrow from '../assets/back-arrow.png';

// import { Swap, Balances } from '../utils';

const Vote = (props) => {

    // const [isEligibleForNyanV1toV2Swap, setIsEligibleForV1ToV2Swap] = useState(false);
    // const [isLGEOver, setIsLGEOver] = useState(false);
    // const [nyanBalance, setNyanBalance]  = useState(0);

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {
            // setNyanBalance(await Balances.getMyNyanBalance());  
            // setIsEligibleForV1ToV2Swap(true);
            // setIsLGEOver(Swap.getLGEBool());
            // console.log(isEligibleForNyanV1toV2Swap)
        }
        init()
    }, []);

    return (
        <Container fluid>
           <Link to="/" className="router-link">
                <div className="back-button">
                    <img className="b-arrow-img" alt="back arrow" src={backArrow}/>
                </div>
           </Link>

           <div className="apps-container">
               <div className="approved-apps">APPROVED</div>
               <div className="pending-apps">PENDING</div>
           </div>
        
            <div className="site-line"></div>

            <h1 className="no-props">No proposals yet...</h1>
            
        </Container>
    )
}

export default Vote;